import Vue from 'vue'
import Router from 'vue-router'
import { getToken } from '../utils/TokenCache';

const originalPush = Router.prototype.push

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

const routes = [
    {
        path:'/',
        redirect:'/login',
        requiresAuth:false,
    },
    {
        path: '/login',
        name:'登录',
        component: () => import(`@/components/login`),
        requiresAuth:false,
    },
    {
        path: '/index',
        name: '首页',
        component: () => import(`@/components/index`),
        requiresAuth:true,
        children: [
            {
                path:'',
                index:'/index/dayprofit',
                name:'dayprofit',
                component:() => import(`@/components/dayprofit`),
                requiresAuth:true
            },
            {
                path:'monthprofit',
                index:'/index/monthprofit',
                name:'monthprofit',
                component:() => import(`@/components/monthprofit`),
                requiresAuth:true
            }
        ]

    },
    {
        path: '*',
        name:'404',
        component: () => import(`@/components/nopage`)
    }
]

let router = new Router({ routes })

router.beforeEach((to, from, next) => {
    let token = getToken()
    if (token) {
        if (to.query.redirect) {
            next({path: to.query.redirect})
        } else {
            if (['/login'].includes(to.path)) {
                next({path: '/index'})
            } else {
                next()
            }
        }
    } else {
        let requiresAuth = to.matched.some(record => record.meta.requiresAuth)
        if (requiresAuth) {
            next({path: '/login', query: { redirect: to.fullPath }})
        } else {
            next()
        }
    }
})

export default router
