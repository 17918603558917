const TokenKey = 'lbian-Token'

export function getToken() {
    return sessionStorage.getItem(TokenKey);
}
export function setToken(token) {
    return sessionStorage.setItem(TokenKey,token);
}
export function removeToken() {
    sessionStorage.removeItem(TokenKey)
}
